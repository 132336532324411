<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.account')"
      :has-action="false"
    >
      <template v-slot:additional-actions>
        <div class="mt-4 d-flex">
          <v-chip
            label
            class="px-4"
            :class="{ 'blue--text': activeTab === 'profile' }"
            :color="activeTab === 'profile' ? 'blue-light' : 'gray-light'"
            @click="activeTab = 'profile'"
          >
            {{ $t('profile') }}
          </v-chip>

          <v-chip
            label
            class="ml-2 px-4"
            :class="{ 'blue--text': activeTab === 'security' }"
            :color="activeTab === 'security' ? 'blue-light' : 'gray-light'"
            @click="activeTab = 'security'"
          >
            {{ $t('security') }}
          </v-chip>
        </div>
      </template>
    </section-header>

    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <update-profile v-if="activeTab === 'profile'" />
          <change-password v-else />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import SectionHeader from '@/components/Form/SectionHeader.vue';
import UpdateProfile from '@/components/Settings/Account/UpdateProfile.vue';
import ChangePassword from '@/components/Settings/Account/ChangePassword.vue';

export default {
  name: 'Account',

  components: {
    SectionHeader,
    UpdateProfile,
    ChangePassword,
  },

  data () {
    return {
      activeTab: 'profile',
    }
  },
}
</script>